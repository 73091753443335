import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import AttendanceDetailsModal from './AttendanceDetailsModal';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import myImage from '../../images/employee_profile.png';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function AttendanceList({ handleLogout, username }) {
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [filteredAttendance, setFilteredAttendance] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showAttendanceDetails, setShowAttendanceDetails] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Initialize with current month
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalHalfDay, setTotalHalfDay] = useState(0);
    const [totalPaidLeave, setTotalPaidLeave] = useState(0);
    const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
    const [totalAttencance, setTotalAttencance] = useState(0);
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [daysAttendance, setDaysAttendance] = useState(false);
    const [totalDays, setTotalDays] = useState(0); // State for total days in selected month
    const [loading, setLoading] = useState(false); // State for loading status

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        if (selectedDepartment) {
            fetchEmployees(selectedDepartment);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedEmployee) {
            fetchAttendanceRecords(selectedEmployee);
        }
    }, [selectedEmployee, selectedMonth, selectedYear]); // Update on selectedEmployee, selectedMonth, and selectedYear changes

    useEffect(() => {
        // Calculate total days in selected month and update state
        const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        console.log("Total Attendance", totalAttencance)
        setTotalDays(daysInMonth);
    }, [selectedEmployee, selectedMonth, selectedYear]);

    const fetchDepartments = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchAttendanceRecords = async (employeeId) => {
        setLoading(true);
        try {
            const attendanceResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
            const attendanceData = attendanceResponse.data || [];

            // Combine with existing attendance records
            const combinedData = attendanceData.map(attendance => {
                return {
                    ...attendance,
                    employeeName: employees.find(emp => emp.id === attendance.employee_id)?.employeeName || '',
                    passportSizePhoto: employees.find(emp => emp.id === attendance.employee_id)?.passportSizePhoto || ''
                };
            });

            setAttendanceRecords(combinedData);
            filterAttendanceRecords(selectedMonth, selectedYear, combinedData); // Initial filter based on selected month and year
        } catch (error) {
            console.error('Error fetching attendance records:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching is complete
        }
    };

    const filterAttendanceRecords = (month, year, data) => {
        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        const presentCount = filteredRecords.filter(record =>
            ['present'].includes(record.status.toLowerCase())
        ).length;
        const absentCount = filteredRecords.filter(record =>
            ['absent'].includes(record.status.toLowerCase())
        ).length;
        const halfDayCount = filteredRecords.filter(record =>
            ['half day'].includes(record.status.toLowerCase())
        ).length;
        const paidLeaveCount = filteredRecords.filter(record =>
            ['paid leave'].includes(record.status.toLowerCase())
        ).length;
        const unpaidLeaveCount = filteredRecords.filter(record =>
            ['unpaid leave'].includes(record.status.toLowerCase())
        ).length;
        const overtimeCount = filteredRecords.filter(record =>
            ['overtime'].includes(record.status.toLowerCase())
        ).length;
        const weeklyOffCount = filteredRecords.filter(record =>
            ['weekly off'].includes(record.status.toLowerCase())
        ).length;

        const TotalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
        console.log("TotalAttendance ", TotalAttendance);
        console.log("month:", totalDays)
        setTotalAttencance(TotalAttendance);
        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalHalfDay(halfDayCount);
        setTotalPaidLeave(paidLeaveCount);
        setTotalUnpaidLeave(unpaidLeaveCount);
        setTotalOvertime(overtimeCount);
        setTotalWeeklyOff(weeklyOffCount);
        setFilteredAttendance(filteredRecords);
    };


    const handleAttendanceDetails = () => {
        setSelectedRecord({
            employeeId: selectedEmployee ? employees.find(emp => emp.id === parseInt(selectedEmployee))?.id : '',
            employeeName: selectedEmployee ? employees.find(emp => emp.id === parseInt(selectedEmployee))?.employeeName : '',
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredAttendance.length > 0 ? filteredAttendance[0].status : '',
            attendanceData: { filteredAttendance },
            totalPresent,
            totalAbsent,
            totalHalfDay,
            totalPaidLeave,
            totalUnpaidLeave,
            totalOvertime,
            totalWeeklyOff,
            totalAttencance
        });
        setShowSidebar(false); // Set to false to hide sidebar
        setShowSearchBar(false);
        setShowAttendanceDetails(true);
    };


    const handleClosePreview = () => {
        setShowSidebar(true); // Set to true to hide sidebar
        setShowSearchBar(true);
        setShowAttendanceDetails(false);
    };

    const handleDownloadAttendance = () => {
        if (selectedEmployee && filteredAttendance.length > 0) {
            const data = filteredAttendance.map(record => ({
                Name: record.employeeName,
                Date: new Date(record.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                Status: record.status
            }));

            // Create the summary data
            const summaryData = [{
                Name: 'Summary',
                Date: '',
                Status: '',
                Present: totalPresent,
                Absent: totalAbsent,
                'Half Day': totalHalfDay,
                'Paid Leave': totalPaidLeave,
                'Unpaid Leave': totalUnpaidLeave,
                Overtime: totalOvertime,
                'Weekly Off': totalWeeklyOff
            }];

            // Combine the data and summaryData
            const combinedData = [...data, {}, ...summaryData];

            const worksheet = XLSX.utils.json_to_sheet(combinedData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance');

            // Generate a Blob object containing the XLSX file
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Trigger file download
            saveAs(blob, `${selectedEmployee}-Attendance.xlsx`);
        } else {
            toast.error('Please select an employee and apply filters before downloading.');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };


    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {showAttendanceDetails ? (
                        <AttendanceDetailsModal
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (<div className="row">
                        <div className="col-xl-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">Attendance List</h6>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <label className='pt-2 text-black fw-bolder'>Department: </label>
                                        <select className="form-control" value={selectedDepartment}
                                            onChange={(e) => setSelectedDepartment(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {departments.map(dept => (
                                                <option key={dept.id} value={dept.id}>{dept.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <label className='pt-2 text-black fw-bolder'>Employee:</label>
                                        <select className="form-control" value={selectedEmployee}
                                            onChange={(e) => setSelectedEmployee(e.target.value)}
                                        >
                                            <option value="">Select Employee</option>
                                            {employees.map(emp => (
                                                <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                        <select className="form-control" value={selectedMonth}
                                            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                        >
                                            <option value="">Select Month</option>
                                            {Array.from({ length: 12 }, (_, i) => (
                                                <option key={i} value={i}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                            ))}
                                        </select>
                                        <select className="form-control" value={selectedYear}
                                            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                        >
                                            <option value="">Select Year</option>
                                            {Array.from({ length: 10 }, (_, i) => (
                                                <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <button className="btn btn-outline-success" onClick={handleDownloadAttendance}><i className="fa fa-download"></i> Excel</button>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <button className="btn btn-outline-primary" onClick={handleAttendanceDetails}><i className="fa fa-download"></i> PDF</button>
                                    </div>
                                </div>
                                <div className="card-body form-row">
                                    <div className='col-md-10' style={{ maxHeight: "520px", overflowY: "auto" }}>
                                        {loading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* Correct usage of spinner */}
                                                <ThreeDots
                                                    color="#00BFFF"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        ) : (
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Photo</th>
                                                        <th>Name</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAttendance.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">No Attendance Found First Select the Employee.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredAttendance
                                                            .sort((a, b) => new Date(a.date) - new Date(b.date))
                                                            .map(record => (
                                                                <tr key={`${record.id}-${record.date}`}>
                                                                    <td>
                                                                        <img
                                                                            src={
                                                                                record.passportSizePhoto
                                                                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}`
                                                                                    : myImage
                                                                            }
                                                                            style={{ width: "50px" }}
                                                                            alt="Employee"
                                                                        />
                                                                    </td>
                                                                    <td>{record.employeeName}</td>
                                                                    <td>{formatDate(record.date)}</td>
                                                                    <td>{record.status}</td>
                                                                </tr>
                                                            ))
                                                    )}
                                                </tbody>

                                            </table>
                                        )}
                                    </div>
                                    <div className="card-footer col-md-2">
                                        <h5 className="text-primary text-center fw-semibold">Summary Days</h5>
                                        <hr />
                                        <div className="d-flex gap-3 flex-column">
                                            <div className="bg-success text-white p-2 text-center border rounded">Present: {totalPresent}</div>
                                            <div className="bg-danger text-white p-2 text-center border rounded">Absent: {totalAbsent}</div>
                                            <div className="bg-info text-white p-2 text-center border rounded">Half Day: {totalHalfDay}</div>
                                            <div className="bg-warning text-white p-2 text-center border rounded">Weekly Off: {totalWeeklyOff}</div>
                                            <div className="bg-warning text-white p-2 text-center border rounded">Unpaid Leave: {totalUnpaidLeave}</div>
                                            <div className="bg-warning text-white p-2 text-center border rounded">Paid Leave: {totalPaidLeave}</div>
                                            <div className="bg-primary text-white p-2 text-center border rounded">Overtime: {totalOvertime}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AttendanceList;









