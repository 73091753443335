// import { Link } from 'react-router-dom';
// import './Dashboard.css';
// import axios from 'axios';
// import { FaEdit } from 'react-icons/fa'; // Import the FaEdit icon from Font Awesome
// import { useState, useEffect } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SidebarEmployee from '../components/sidebar/SidebarEmployee';
// import SearchBarEmployee from '../components/sidebar/SearchBarEmployee';
// import myImage from '../images/employee_profile.png';
// import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


// function UserDashboard({ handleLogout, username }) {
//     const [setting, setSetting] = useState({});
//     const [isLoading, setIsLoading] = useState(false);
//     const [employeeDetails, setEmployeeDetails] = useState({});

//     // profile 
//     const employeeId = localStorage.getItem('employeeId'); // Fix: Correctly retrieve employeeId from localStorage

//     useEffect(() => {

//         if (employeeId) { // Fetch only if employeeId exists
//             fetchEmployeeDetails(employeeId);
//         }
//         fetchSetting();
//     }, [employeeId]); // Fix: Add employeeId as a dependency

//     useEffect(() => {
//         // Check if the page has already been reloaded during this session
//         const hasReloaded = sessionStorage.getItem('hasReloaded');

//         // Reload the page if this is the first visit
//         if (!hasReloaded) {
//             sessionStorage.setItem('hasReloaded', 'true');
//             window.location.reload(); // Reload the page
//         }

//         // Fetch employee details and settings if employeeId exists
//         if (employeeId) {
//             fetchEmployeeDetails(employeeId);
//         }
//         fetchSetting();
//     }, [employeeId]);

//     const fetchSetting = async () => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//             setSetting(response.data);
//             console.log("setting ", response.data);
//         } catch (error) {
//             console.error('Error fetching settings', error);
//         }
//         finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchEmployeeDetails = async (employeeId) => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             const employeeData = response.data.length > 0 ? response.data[0] : null;
//             // Assuming the response data is an object, not an array
//             // const employeeData = response.data;
//             setEmployeeDetails(employeeData);
//             console.log("employeeDetails", employeeData);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
//     };

//     return (
//         <div className=''>
//             <div className='d-flex w-100 h-100 bg-white'>
//                 <SidebarEmployee />
//                 <div className='w-100'>
//                     <SearchBarEmployee username={username} handleLogout={handleLogout} />
//                     <div className="container-fluid ">
//                         {/*  <!-- Page Heading --> */}
//                         {/*  <!-- Content Row --> */}
//                         <div className=" bg-white rounded shadow-sm p-1">
//                             <div className=''>
//                                 <div className="">
//                                     <div className="card-body d-flex align-items-center justify-content-center flex-column">
//                                         <div style={{ maxHeight: "30vh", maxWidth: "30vh", overflow: "hidden" }} className='d-flex bg-dark rounded-circle align-items-center justify-content-center'>
//                                             <img
//                                                 src={employeeDetails.passportSizePhoto
//                                                     ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employeeDetails.passportSizePhoto}`
//                                                     : myImage}
//                                                 className='w-100 h-100'
//                                                 alt="Employee"
//                                             />
//                                         </div>
//                                         {/* <FaEdit style={{ position: 'absolute', top: '60px', right: '130px', cursor: 'pointer' }} /> */}
//                                         <h3 style={{ color: "#00509d" }} className="title-detail fw-bolder text-center font-bold m-2">{employeeDetails.employeeName}</h3>
//                                         <small style={{ color: "#00509d" }} className="text-center text-capitalize fw-bolder">Status - {employeeDetails.status}</small>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* content  */}
//                             <div className="row">
//                                 {/*  <!-- Total employee Card Example --> */}
//                                 <div className="col-xl-8 col-md-6 mb-4">
//                                     <div className="card  border-left-dark shadow h-100 py-1">
//                                         <div className="card-body">
//                                             <div className="row no-gutters align-items-center">
//                                                 <div className="col mr-2">
//                                                     <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                                         Basic Details  <i className="fa fa-arrow-right"></i></div>
//                                                 </div>
//                                             </div>
//                                             <div className="row no-gutters align-items-center">
//                                                 {isLoading ? (
//                                                     <div className="d-flex justify-content-center align-items-center">
//                                                         {/* Correct usage of spinner */}
//                                                         <ThreeDots
//                                                             color="#00BFFF"
//                                                             height={80}
//                                                             width={80}
//                                                         />
//                                                     </div>
//                                                 ) : (
//                                                     <div className="col-md-12">
//                                                         <hr className="my-2" />
//                                                         <div class="row">
//                                                             <div class="col-sm">
//                                                                 <h5>Name: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeName || "N/A"}</span></h5>
//                                                                 <h5>Phone No.: <span style={{ fontSize: '18px' }}>{employeeDetails.employeePhone || "N/A"}</span></h5>
//                                                                 <h5>Email: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeEmail || "N/A"}</span></h5>
//                                                                 <h5>Gender: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeGender || "N/A"}</span></h5>
//                                                                 <h5>PAN No.: <span style={{ fontSize: '18px' }}>{employeeDetails.employeePan || "N/A"}</span></h5>
//                                                             </div>
//                                                             <div class="col-sm">
//                                                                 <h5>Employee Code: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeCode || "N/A"}</span></h5>
//                                                                 <h5>Alternative Phone: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeAltPhone || "N/A"}</span></h5>
//                                                                 <h5>DOB: <span style={{ fontSize: '18px' }}>{formatDate(employeeDetails.employeeDOB) || "N/A"}</span></h5>
//                                                                 <h5>Blood Group: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeBloodGroup || "N/A"}</span></h5>
//                                                                 <h5>Aadhar Card No.: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeAadhar || "N/A"}</span></h5>
//                                                             </div>
//                                                         </div>
//                                                         <h4 className='fw-bolder'>Current Address :</h4>
//                                                         <div class="row">
//                                                             <div class="col-sm">
//                                                                 <h5>Address : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeAddress1 || "N/A"}</span></h5>
//                                                             </div>
//                                                             <div class="col-sm">
//                                                                 <h5>City : <span style={{ fontSize: "18px", textAlign: "start" }}>{employeeDetails.employeeCity1 || "N/A"}</span></h5>
//                                                             </div>
//                                                         </div>
//                                                         <div class="row">
//                                                             <div class="col-sm">
//                                                                 <h5>State : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeState1 || "N/A"}</span></h5>
//                                                             </div>
//                                                             <div class="col-sm">
//                                                                 <h5>Pincode : <span style={{ fontSize: "18px" }}>{employeeDetails.employeePincode1 || "N/A"}</span></h5>
//                                                             </div>
//                                                         </div>

//                                                         <h4 className='fw-bolder'>Permanent Address : </h4>


//                                                         <div class="row">
//                                                             <div class="col-sm">
//                                                                 <h5>Address : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeAddress2 || "N/A"}</span></h5>
//                                                             </div>
//                                                             <div class="col-sm">
//                                                                 <h5>City : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeCity2 || "N/A"}</span></h5>
//                                                             </div>
//                                                         </div>
//                                                         <div class="row">
//                                                             <div class="col-sm">
//                                                                 <h5>State : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeState2 || "N/A"}</span></h5>
//                                                             </div>
//                                                             <div class="col-sm">
//                                                                 <h5>Pincode : <span style={{ fontSize: "18px" }}>{employeeDetails.employeePincode2 || "N/A"}</span></h5>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {/*  <!-- Total Inward Card Example --> */}
//                                 <div className="col-xl-4 col-md-6 mb-4">
//                                     <div className="card border-left-dark shadow h-100 py-1">
//                                         <div className="card-body">
//                                             <div className="row no-gutters align-items-center">
//                                                 <div className="col mr-2">
//                                                     <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                                         Family Details <i className="fa fa-arrow-right"></i></div>
//                                                 </div>
//                                             </div>
//                                             <div className="row no-gutters align-items-center">
//                                             {isLoading ? (
//                                                 <div className="d-flex justify-content-center align-items-center">
//                                                     {/* Correct usage of spinner */}
//                                                     <ThreeDots
//                                                         color="#00BFFF"
//                                                         height={80}
//                                                         width={80}
//                                                     />
//                                                 </div>
//                                             ) : (
//                                                 <div className="col-md-12">
//                                                     <hr className="my-2" /> {/* Line added here */}
//                                                     <h5>Father Name : <span style={{ fontSize: "18px" }}>{employeeDetails.fatherName || "N/A"}</span></h5>
//                                                     <h5>Mother Name : <span style={{ fontSize: "18px" }}>{employeeDetails.motherName || "N/A"}</span></h5>
//                                                     <h5>Marital Status: <span style={{ fontSize: "18px" }}>{employeeDetails.employeeMaritalStatus || "N/A"}</span></h5>

//                                                     {employeeDetails.employeeMaritalStatus === 'married' && (
//                                                         <>
//                                                             {employeeDetails.spouseName && (
//                                                                 <h5>Spouse Name: <span style={{ fontSize: "18px" }}>{employeeDetails.spouseName || "N/A"}</span></h5>
//                                                             )}

//                                                             {employeeDetails.haveChildren === 'yes' && employeeDetails.children && (
//                                                                 <>
//                                                                     <h5>Children:</h5>
//                                                                     {JSON.parse(employeeDetails.children).map((child, index) => (
//                                                                         <p key={index} style={{ marginLeft: "20px" }}>
//                                                                             <span style={{ fontSize: "16px" }}>Name: {child.name}, DOB: {child.dob}</span>
//                                                                         </p>
//                                                                     ))}
//                                                                 </>
//                                                             )}
//                                                         </>
//                                                     )}

//                                                     {(employeeDetails.employeeMaritalStatus === 'widowed' || employeeDetails.employeeMaritalStatus === 'divorced') && (
//                                                         <>
//                                                             {employeeDetails.haveChildren === 'yes' && employeeDetails.children && (
//                                                                 <>
//                                                                     <h5>Children:</h5>
//                                                                     {JSON.parse(employeeDetails.children).map((child, index) => (
//                                                                         <p key={index} style={{ marginLeft: "20px" }}>
//                                                                             <span style={{ fontSize: "16px" }}>Name: {child.name}, DOB: {child.dob}</span>
//                                                                         </p>
//                                                                     ))}
//                                                                 </>
//                                                             )}
//                                                         </>
//                                                     )}

//                                                     <h5>Contact Name 1: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactName1 || "N/A"}</span></h5>
//                                                     <h5>Contact Number 1: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactNumber1 || "N/A"}</span></h5>
//                                                     <h5>Contact Relation 1: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactRelation1 || "N/A"}</span></h5>

//                                                     <h5>Contact Name 2: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactName2 || "N/A"}</span></h5>
//                                                     <h5>Contact Number 2: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactNumber2 || "N/A"}</span></h5>
//                                                     <h5>Contact Relation 2: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactRelation2 || "N/A"}</span></h5>
//                                                 </div>
//                                             )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* Content  */}
//                             <div className="row">
//                                 {/*  <!-- Total employee Card Example --> */}
//                                 <div className="col-xl-4 col-md-6 mb-4">
//                                     <div className="card  border-left-dark shadow h-100 py-1">
//                                         <div className="card-body">
//                                             <div className="row no-gutters align-items-center">
//                                                 <div className="col mr-2">
//                                                     <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                                         Position Details <i className="fa fa-arrow-right"></i></div>
//                                                 </div>
//                                             </div>
//                                             <div className="row no-gutters align-items-center">
//                                             {isLoading ? (
//                                                 <div className="d-flex justify-content-center align-items-center">
//                                                     {/* Correct usage of spinner */}
//                                                     <ThreeDots
//                                                         color="#00BFFF"
//                                                         height={80}
//                                                         width={80}
//                                                     />
//                                                 </div>
//                                             ) : (
//                                                 <div className="col-md-12">
//                                                     <hr className="my-2" />
//                                                     <h5>Department Name : <span style={{ fontSize: "18px" }}>{employeeDetails.departmentName || "N/A"}</span></h5>
//                                                     <h5>Designation Name  : <span style={{ fontSize: "18px" }}>{employeeDetails.positionName || "N/A"}</span></h5>
//                                                     <h5>Joining Company  : <span style={{ fontSize: "18px" }}>{employeeDetails.joiningCompany || "N/A"}</span></h5>
//                                                     <h5>Joining Office   : <span style={{ fontSize: "18px" }}>{employeeDetails.joiningOffice || "N/A"}</span></h5>
//                                                     <h5>Employee Type  : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeType || "N/A"}</span></h5>
//                                                     <h5>Joining Date  : <span style={{ fontSize: "18px" }}>{formatDate(employeeDetails.joiningDate) || "N/A"}</span></h5>
//                                                     <h5>Inter/Contractual End Date : <span style={{ fontSize: "18px" }}>{formatDate(employeeDetails.interncontractual) || "N/A"}</span></h5>
//                                                 </div>
//                                             )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {/*  <!-- Total Inward Card Example --> */}
//                                 <div className="col-xl-4 col-md-6 mb-4">
//                                     <div className="card border-left-dark shadow h-100 py-1">
//                                         <div className="card-body">
//                                             <div className="row no-gutters align-items-center">
//                                                 <div className="col mr-2">
//                                                     <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                                         Salary Details <i className="fa fa-arrow-right"></i></div>
//                                                 </div>
//                                             </div>
//                                             <div className="row no-gutters align-items-center">
//                                             {isLoading ? (
//                                                 <div className="d-flex justify-content-center align-items-center">
//                                                     {/* Correct usage of spinner */}
//                                                     <ThreeDots
//                                                         color="#00BFFF"
//                                                         height={80}
//                                                         width={80}
//                                                     />
//                                                 </div>
//                                             ) : (
//                                                 <div className="col-md-12">
//                                                     <hr className="my-2" /> {/* Line added here */}
//                                                     <h5>Basic Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.basicSalary || "N/A"}</span></h5>
//                                                     <h5>VDA: <span style={{ fontSize: "18px" }}>{employeeDetails.vda || "N/A"}</span></h5>
//                                                     <h5>HA: <span style={{ fontSize: "18px" }}>{employeeDetails.houserentallowances || "N/A"}</span></h5>
//                                                     <h5>CA: <span style={{ fontSize: "18px" }}>{employeeDetails.conveyanceallowances || "N/A"}</span></h5>
//                                                     <h5>DA: <span style={{ fontSize: "18px" }}>{employeeDetails.dearnessallowances || "N/A"}</span></h5>
//                                                     <h5>SA: <span style={{ fontSize: "18px" }}>{employeeDetails.specialallowances || "N/A"}</span></h5>
//                                                     <h5>Gross Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.grossSalary || "N/A"}</span></h5>

//                                                     {employeeDetails.epfesicApplicable === "YES" && (
//                                                         <div>
//                                                             <h5>EPF & ESIC Applicable: <span style={{ fontSize: "18px" }}>{employeeDetails.epfesicApplicable || "N/A"}</span></h5>
//                                                             <h5>EPF 12% (Employer): <span style={{ fontSize: "18px" }}>{employeeDetails.epfEmployer || "N/A"}</span></h5>
//                                                             <h5>ESIC 3.25% (Employer): <span style={{ fontSize: "18px" }}>{employeeDetails.esicEmployer || "N/A"}</span></h5>
//                                                             <h5>Total Employer Contribution: <span style={{ fontSize: "18px" }}>{employeeDetails.totalEmployerContribution || "N/A"}</span></h5>
//                                                             <h5>EPF 12% (Employee): <span style={{ fontSize: "18px" }}>{employeeDetails.epfEmployee || "N/A"}</span></h5>
//                                                             <h5>ESIC 0.75% (Employee): <span style={{ fontSize: "18px" }}>{employeeDetails.esicEmployee || "N/A"}</span></h5>
//                                                             <h5>Total Employee Deduction: <span style={{ fontSize: "18px" }}>{employeeDetails.totalEmployeeDeduction || "N/A"}</span></h5>
//                                                         </div>
//                                                     )}

//                                                     {employeeDetails.tdsApplicable === "YES" && (
//                                                         <div>
//                                                             <h5>Total TDS Deduction: <span style={{ fontSize: "18px" }}>{employeeDetails.totalTdsDeduction || "N/A"}</span></h5>
//                                                         </div>
//                                                     )}

//                                                     <h5>Total In Hand Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.totalInHandSalary || "N/A"}</span></h5>
//                                                     <h5>Total Payable Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.totalPayableSalary || "N/A"}</span></h5>
//                                                 </div>
//                                             )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {/*  <!-- Total employee Card Example --> */}
//                                 <div className="col-xl-4 col-md-6 mb-4">
//                                     <div className="card  border-left-dark shadow h-100 py-1">
//                                         <div className="card-body">
//                                             <div className="row no-gutters align-items-center">
//                                                 <div className="col mr-2">
//                                                     <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                                         Account Details <i className="fa fa-arrow-right"></i></div>
//                                                     <div className="h5 mb-0 font-weight-bold text-gray-800">{ }</div> {/* Updated amount */}
//                                                 </div>
//                                             </div>
//                                             <div className="row no-gutters align-items-center">
//                                             {isLoading ? (
//                                                 <div className="d-flex justify-content-center align-items-center">
//                                                     {/* Correct usage of spinner */}
//                                                     <ThreeDots
//                                                         color="#00BFFF"
//                                                         height={80}
//                                                         width={80}
//                                                     />
//                                                 </div>
//                                             ) : (
//                                                 <div className="col-md-12">
//                                                     <hr className="my-2" />
//                                                     <h5>Account Holder Name  : <span style={{ fontSize: "18px" }}>{employeeDetails.accountHolderName || "N/A"}</span></h5>
//                                                     <h5>Account Number  : <span style={{ fontSize: "18px" }}>{employeeDetails.accountNumber || "N/A"}</span></h5>
//                                                     <h5>Bank Name  : <span style={{ fontSize: "18px" }}>{employeeDetails.bankName || "N/A"}</span></h5>
//                                                     <h5>IFSC Code  : <span style={{ fontSize: "18px" }}>{employeeDetails.ifscCode || "N/A"}</span></h5>
//                                                     <h5>Branch Name   : <span style={{ fontSize: "18px" }}>{employeeDetails.branchName || "N/A"}</span></h5>
//                                                 </div>
//                                             )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >

//     )
// }

// export default UserDashboard;






import { Link } from 'react-router-dom';
import './Dashboard.css';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa'; // Import the FaEdit icon from Font Awesome
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SidebarEmployee from '../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../components/sidebar/SearchBarEmployee';
import myImage from '../images/employee_profile.png';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function UserDashboard({ handleLogout, username }) {
    const [setting, setSetting] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState({});

    // profile 
    const employeeId = localStorage.getItem('employeeId'); // Fix: Correctly retrieve employeeId from localStorage

    useEffect(() => {

        if (employeeId) { // Fetch only if employeeId exists
            fetchEmployeeDetails(employeeId);
        }
        fetchSetting();
    }, [employeeId]); // Fix: Add employeeId as a dependency

    useEffect(() => {
        // Check if the page has already been reloaded during this session
        const hasReloaded = sessionStorage.getItem('hasReloaded');

        // Reload the page if this is the first visit
        if (!hasReloaded) {
            sessionStorage.setItem('hasReloaded', 'true');
            window.location.reload(); // Reload the page
        }

        // Fetch employee details and settings if employeeId exists
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
        }
        fetchSetting();
    }, [employeeId]);

    const fetchSetting = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
            setSetting(response.data);
            console.log("setting ", response.data);
        } catch (error) {
            console.error('Error fetching settings', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const fetchEmployeeDetails = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            const employeeData = response.data.length > 0 ? response.data[0] : null;
            // Assuming the response data is an object, not an array
            // const employeeData = response.data;
            setEmployeeDetails(employeeData);
            console.log("employeeDetails", employeeData);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    return (
        <div className=''>
            <div className='d-flex w-100 h-100 bg-white'>
                <SidebarEmployee />
                <div className='w-100'>
                    <SearchBarEmployee username={username} handleLogout={handleLogout} />
                    <div className="container-fluid ">
                        {/*  <!-- Page Heading --> */}
                        {/*  <!-- Content Row --> */}
                        <div className=" bg-white rounded shadow-sm p-1">
                            <div className=''>
                                <div className="">
                                    <div className="card-body d-flex align-items-center justify-content-center flex-column">
                                        <div style={{ maxHeight: "30vh", maxWidth: "30vh", overflow: "hidden" }} className='d-flex bg-dark rounded-circle align-items-center justify-content-center'>
                                            <img
                                                src={employeeDetails.passportSizePhoto
                                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employeeDetails.passportSizePhoto}`
                                                    : myImage}
                                                className='w-100 h-100'
                                                alt="Employee"
                                            />
                                        </div>
                                        {/* <FaEdit style={{ position: 'absolute', top: '60px', right: '130px', cursor: 'pointer' }} /> */}
                                        <h3 style={{ color: "#00509d" }} className="title-detail fw-bolder text-center font-bold m-2">{employeeDetails.employeeName}</h3>
                                        <small style={{ color: "#00509d" }} className="text-center text-capitalize fw-bolder">Status - {employeeDetails.status}</small>
                                    </div>
                                </div>
                            </div>
                            {/* content  */}
                            <div className="row">
                                {/*  <!-- Total employee Card Example --> */}
                                <div className="col-xl-8 col-md-6 mb-4">
                                    <div className="card  border-left-dark shadow h-100 py-1">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                        Basic Details  <i className="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        {/* Correct usage of spinner */}
                                                        <ThreeDots
                                                            color="#00BFFF"
                                                            height={80}
                                                            width={80}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="col-md-12">
                                                        <hr className="my-2" />
                                                        <div class="row">
                                                            <div class="col-sm">
                                                                <h5>Name: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeName || "N/A"}</span></h5>
                                                                <h5>Phone No.: <span style={{ fontSize: '18px' }}>{employeeDetails.employeePhone || "N/A"}</span></h5>
                                                                <h5>Email: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeEmail || "N/A"}</span></h5>
                                                                <h5>Gender: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeGender || "N/A"}</span></h5>
                                                                <h5>PAN No.: <span style={{ fontSize: '18px' }}>{employeeDetails.employeePan || "N/A"}</span></h5>
                                                            </div>
                                                            <div class="col-sm">
                                                                <h5>Employee Code: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeCode || "N/A"}</span></h5>
                                                                <h5>Alternative Phone: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeAltPhone || "N/A"}</span></h5>
                                                                <h5>DOB: <span style={{ fontSize: '18px' }}>{formatDate(employeeDetails.employeeDOB) || "N/A"}</span></h5>
                                                                <h5>Blood Group: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeBloodGroup || "N/A"}</span></h5>
                                                                <h5>Aadhar Card No.: <span style={{ fontSize: '18px' }}>{employeeDetails.employeeAadhar || "N/A"}</span></h5>
                                                            </div>
                                                        </div>
                                                        <h4 className='fw-bolder'>Current Address :</h4>
                                                        <div class="row">
                                                            <div class="col-sm">
                                                                <h5>Address : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeAddress1 || "N/A"}</span></h5>
                                                            </div>
                                                            <div class="col-sm">
                                                                <h5>City : <span style={{ fontSize: "18px", textAlign: "start" }}>{employeeDetails.employeeCity1 || "N/A"}</span></h5>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm">
                                                                <h5>State : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeState1 || "N/A"}</span></h5>
                                                            </div>
                                                            <div class="col-sm">
                                                                <h5>Pincode : <span style={{ fontSize: "18px" }}>{employeeDetails.employeePincode1 || "N/A"}</span></h5>
                                                            </div>
                                                        </div>

                                                        <h4 className='fw-bolder'>Permanent Address : </h4>


                                                        <div class="row">
                                                            <div class="col-sm">
                                                                <h5>Address : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeAddress2 || "N/A"}</span></h5>
                                                            </div>
                                                            <div class="col-sm">
                                                                <h5>City : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeCity2 || "N/A"}</span></h5>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm">
                                                                <h5>State : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeState2 || "N/A"}</span></h5>
                                                            </div>
                                                            <div class="col-sm">
                                                                <h5>Pincode : <span style={{ fontSize: "18px" }}>{employeeDetails.employeePincode2 || "N/A"}</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*  <!-- Total Inward Card Example --> */}
                                <div className="col-xl-4 col-md-6 mb-4">
                                    <div className="card border-left-dark shadow h-100 py-1">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                        Family Details <i className="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="col-md-12">
                                                    <hr className="my-2" /> {/* Line added here */}
                                                    <h5>Father Name : <span style={{ fontSize: "18px" }}>{employeeDetails.fatherName || "N/A"}</span></h5>
                                                    <h5>Mother Name : <span style={{ fontSize: "18px" }}>{employeeDetails.motherName || "N/A"}</span></h5>
                                                    <h5>Marital Status: <span style={{ fontSize: "18px" }}>{employeeDetails.employeeMaritalStatus || "N/A"}</span></h5>

                                                    {employeeDetails.employeeMaritalStatus === 'married' && (
                                                        <>
                                                            {employeeDetails.spouseName && (
                                                                <h5>Spouse Name: <span style={{ fontSize: "18px" }}>{employeeDetails.spouseName || "N/A"}</span></h5>
                                                            )}

                                                            {employeeDetails.haveChildren === 'yes' && employeeDetails.children && (
                                                                <>
                                                                    <h5>Children:</h5>
                                                                    {JSON.parse(employeeDetails.children).map((child, index) => (
                                                                        <p key={index} style={{ marginLeft: "20px" }}>
                                                                            <span style={{ fontSize: "16px" }}>Name: {child.name}, DOB: {child.dob}</span>
                                                                        </p>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {(employeeDetails.employeeMaritalStatus === 'widowed' || employeeDetails.employeeMaritalStatus === 'divorced') && (
                                                        <>
                                                            {employeeDetails.haveChildren === 'yes' && employeeDetails.children && (
                                                                <>
                                                                    <h5>Children:</h5>
                                                                    {JSON.parse(employeeDetails.children).map((child, index) => (
                                                                        <p key={index} style={{ marginLeft: "20px" }}>
                                                                            <span style={{ fontSize: "16px" }}>Name: {child.name}, DOB: {child.dob}</span>
                                                                        </p>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    <h5>Contact Name 1: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactName1 || "N/A"}</span></h5>
                                                    <h5>Contact Number 1: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactNumber1 || "N/A"}</span></h5>
                                                    <h5>Contact Relation 1: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactRelation1 || "N/A"}</span></h5>

                                                    <h5>Contact Name 2: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactName2 || "N/A"}</span></h5>
                                                    <h5>Contact Number 2: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactNumber2 || "N/A"}</span></h5>
                                                    <h5>Contact Relation 2: <span style={{ fontSize: "18px" }}>{employeeDetails.emergencyContactRelation2 || "N/A"}</span></h5>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Content  */}
                            <div className="row">
                                {/*  <!-- Total employee Card Example --> */}
                                <div className="col-xl-4 col-md-6 mb-4">
                                    <div className="card  border-left-dark shadow h-100 py-1">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                        Position Details <i className="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="col-md-12">
                                                    <hr className="my-2" />
                                                    <h5>Department Name : <span style={{ fontSize: "18px" }}>{employeeDetails.departmentName || "N/A"}</span></h5>
                                                    <h5>Designation Name  : <span style={{ fontSize: "18px" }}>{employeeDetails.positionName || "N/A"}</span></h5>
                                                    <h5>Joining Company  : <span style={{ fontSize: "18px" }}>{employeeDetails.joiningCompany || "N/A"}</span></h5>
                                                    <h5>Joining Office   : <span style={{ fontSize: "18px" }}>{employeeDetails.joiningOffice || "N/A"}</span></h5>
                                                    <h5>Employee Type  : <span style={{ fontSize: "18px" }}>{employeeDetails.employeeType || "N/A"}</span></h5>
                                                    <h5>Joining Date  : <span style={{ fontSize: "18px" }}>{formatDate(employeeDetails.joiningDate) || "N/A"}</span></h5>
                                                    <h5>Inter/Contractual End Date : <span style={{ fontSize: "18px" }}>{formatDate(employeeDetails.interncontractual) || "N/A"}</span></h5>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*  <!-- Total Inward Card Example --> */}
                                <div className="col-xl-4 col-md-6 mb-4">
                                    <div className="card border-left-dark shadow h-100 py-1">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                        Salary Details <i className="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="col-md-12">
                                                    <hr className="my-2" /> {/* Line added here */}
                                                    <h5>Basic Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.basicSalary || "N/A"}</span></h5>
                                                    <h5>VDA: <span style={{ fontSize: "18px" }}>{employeeDetails.vda || "N/A"}</span></h5>
                                                    <h5>HA: <span style={{ fontSize: "18px" }}>{employeeDetails.houserentallowances || "N/A"}</span></h5>
                                                    <h5>CA: <span style={{ fontSize: "18px" }}>{employeeDetails.conveyanceallowances || "N/A"}</span></h5>
                                                    <h5>DA: <span style={{ fontSize: "18px" }}>{employeeDetails.dearnessallowances || "N/A"}</span></h5>
                                                    <h5>SA: <span style={{ fontSize: "18px" }}>{employeeDetails.specialallowances || "N/A"}</span></h5>
                                                    <h5>Gross Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.grossSalary || "N/A"}</span></h5>

                                                    {employeeDetails.epfesicApplicable === "YES" && (
                                                        <div>
                                                            <h5>EPF & ESIC Applicable: <span style={{ fontSize: "18px" }}>{employeeDetails.epfesicApplicable || "N/A"}</span></h5>
                                                            <h5>EPF 12% (Employer): <span style={{ fontSize: "18px" }}>{employeeDetails.epfEmployer || "N/A"}</span></h5>
                                                            <h5>ESIC 3.25% (Employer): <span style={{ fontSize: "18px" }}>{employeeDetails.esicEmployer || "N/A"}</span></h5>
                                                            <h5>Total Employer Contribution: <span style={{ fontSize: "18px" }}>{employeeDetails.totalEmployerContribution || "N/A"}</span></h5>
                                                            <h5>EPF 12% (Employee): <span style={{ fontSize: "18px" }}>{employeeDetails.epfEmployee || "N/A"}</span></h5>
                                                            <h5>ESIC 0.75% (Employee): <span style={{ fontSize: "18px" }}>{employeeDetails.esicEmployee || "N/A"}</span></h5>
                                                            <h5>Total Employee Deduction: <span style={{ fontSize: "18px" }}>{employeeDetails.totalEmployeeDeduction || "N/A"}</span></h5>
                                                        </div>
                                                    )}

                                                    {employeeDetails.tdsApplicable === "YES" && (
                                                        <div>
                                                            <h5>Total TDS Deduction: <span style={{ fontSize: "18px" }}>{employeeDetails.totalTdsDeduction || "N/A"}</span></h5>
                                                        </div>
                                                    )}

                                                    <h5>Total In Hand Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.totalInHandSalary || "N/A"}</span></h5>
                                                    <h5>Total Payable Salary: <span style={{ fontSize: "18px" }}>{employeeDetails.totalPayableSalary || "N/A"}</span></h5>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*  <!-- Total employee Card Example --> */}
                                <div className="col-xl-4 col-md-6 mb-4">
                                    <div className="card  border-left-dark shadow h-100 py-1">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dark text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                        Account Details <i className="fa fa-arrow-right"></i></div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{ }</div> {/* Updated amount */}
                                                </div>
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="col-md-12">
                                                    <hr className="my-2" />
                                                    <h5>Account Holder Name  : <span style={{ fontSize: "18px" }}>{employeeDetails.accountHolderName || "N/A"}</span></h5>
                                                    <h5>Account Number  : <span style={{ fontSize: "18px" }}>{employeeDetails.accountNumber || "N/A"}</span></h5>
                                                    <h5>Bank Name  : <span style={{ fontSize: "18px" }}>{employeeDetails.bankName || "N/A"}</span></h5>
                                                    <h5>IFSC Code  : <span style={{ fontSize: "18px" }}>{employeeDetails.ifscCode || "N/A"}</span></h5>
                                                    <h5>Branch Name   : <span style={{ fontSize: "18px" }}>{employeeDetails.branchName || "N/A"}</span></h5>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default UserDashboard;
