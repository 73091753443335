import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';
import AddLoanRepayment from './AddLoanRepayment'; // Import the AddLoanRepayment modal
import AddLoan from './AddLoan';
import LoanDetailsModal from './LoanDetailsModal';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner
import EditLoanList from './EditLoanList';
import DeleteConfirmationModal from '../DeleteConfirmationModal';



const LoanList = ({ handleLogout, username }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [loanList, setLoanList] = useState([]);
    const [repaymentDetails, setRepaymentDetails] = useState([]);
    const [selectedLoanForRepayment, setSelectedLoanForRepayment] = useState(null);
    // State for AddLoanRepayment modal
    const [showAddRepaymentModal, setShowAddRepaymentModal] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(null);
    // View Loan Details 
    const [showLoanDetailsModal, setShowLoanDetailsModal] = useState(false);
    const [selectedViewLoan, setSelectedViewLoan] = useState(null);
    const [isAddLoanModalOpen, setIsAddLoanModalOpen] = useState(false);
    // Edit Details
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editLoanList, setEditLoanList] = useState(null);

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);

    };
    const handleEditLoanList = (loan) => {
        setEditLoanList(loan);
        setIsEditModalOpen(true);
    };

    // Edit Details
    // Delete the Advance Details 
    const [deleteLoanList, setDeleteLoanList] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    const handleCloseLoan = () => {
        setIsDeleteModalOpen(false);
    };
    const handleDeleteLoan = (loan) => {
        setDeleteLoanList(loan);
        setIsDeleteModalOpen(true);
    };
    const confirmdeleteLoanList = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/api/loanlist/${deleteLoanList.id}`);
            const deletedLoanList = { ...deleteLoanList, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedLoanList);
            setLoanList((prevloanDetails) =>
                prevloanDetails.filter((loan) => loan.id !== deleteLoanList.id)
            );
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting Department:", error);
        }
    };
    // Delete the Advance Details 

    // Fetch the employee Details 
    useEffect(() => {
        fetchLoanEmployees();
    }, []);
    // Function to fetch all employees with loan details
    const fetchLoanEmployees = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/loanDetails`);
            console.log('Fetched loan employees:', response.data); // Log fetched employees
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching loan employees:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedEmployee) {
            fetchLoanList(selectedEmployee);
            fetchRepaymentDetails(selectedEmployee);
        }
    }, [selectedEmployee]);

    // Function to fetch Loan Details 
    const fetchLoanList = async (selectedEmployee) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${selectedEmployee}`);
            console.log('Fetched loan Repayment Details :', response.data);
            setLoanList(response.data);
        } catch (error) {
            console.error('Error fetching loan Repayment Details:', error);
        } finally {
            setIsLoading(false);
        }
    };
    // Function to fetch repayment details for a specific loan
    const fetchRepaymentDetails = async (loanId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetailsHistory/${loanId}`);
            console.log('Fetched loan Repayment Details :', response.data);
            setRepaymentDetails(response.data);
        } catch (error) {
            console.error('Error fetching loan Repayment Details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle opening AddLoanRepayment modal
    const handleAddRepayment = (loan) => {
        setSelectedLoan(loan);
        setShowAddRepaymentModal(true);
    };

    // Function to handle opening AddLoanRepayment modal
    const handleViewLoanDetails = (loan) => {
        setSelectedViewLoan(loan);
        setShowLoanDetailsModal(true);
    };

    // Function to handle viewing repayment details for a specific loan
    const handleViewRepayment = (loan) => {
        setSelectedLoanForRepayment(loan.id);
        fetchRepaymentDetails(loan.id);
    };


    // Add Loan Modal 
    const handleAddLoanModal = () => {
        setIsAddLoanModalOpen(true);
    };

    const handleCloseLoanModal = () => {
        setIsAddLoanModalOpen(false);
    };
    const handleUpdate = () => {
        toast.success("Successfully uploaded");
    };


    return (
        <div className="d-flex w-100 h-100 bg-white">
            <Sidebar />
            <div className="w-100">
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row bg-white rounded shadow">
                        <div className="col-sm-12 col-md-12">
                            <div className="panel panel-bd">
                                <div className="panel-heading">
                                    <div className="panel-title d-flex align-items-center justify-content-between pt-2">
                                        <h4 className="text-black fw-bolder m-0">Loan List</h4>
                                        <button onClick={handleAddLoanModal} className="btn btn-outline-primary">
                                            <i className="fa fa-plus"></i> Add Loan
                                        </button>
                                    </div>
                                    <hr className='p-1 ' />
                                </div>
                                <div className=" border p-2 rounded">
                                    <div style={{ width: "100%", height: "60%" }} className='d-flex gap-3'>
                                        <div style={{ width: "30%", height: "100%" }} className='bg-light border shadow-sm p-1 rounded'>
                                            <h3 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold p-1 m-0">Employee Details </h3>
                                            <hr />
                                            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Employee Names</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <style>
                                                            {`.hyperlink:hover {color: blue;}`}
                                                        </style>
                                                        {isLoading ? (
                                                            <tr>
                                                                <td colSpan="12" className="d-flex justify-content-center align-items-center">
                                                                    {/* Correct usage of spinner */}
                                                                    <ThreeDots
                                                                        color="#00BFFF"
                                                                        height={80}
                                                                        width={80}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            employees.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">There is No Employee List.</td>
                                                                </tr>
                                                            ) : (
                                                                employees.map(employee => (
                                                                    <tr
                                                                        key={employee.employeeId}
                                                                        className="hyperlink"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setSelectedEmployee(employee.employeeId)}
                                                                    >
                                                                        <td className="hyperlink" style={{ cursor: "pointer" }}>
                                                                            {employee.employeeName}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )
                                                        )}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div style={{ width: "80%", height: "100%" }} className='bg-light border shadow-sm p-1 rounded'>
                                            <h3 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold p-1 m-0">Loan Details</h3>
                                            <hr />
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <ThreeDots color="#00BFFF" height={80} width={80} />
                                                </div>
                                            ) : (
                                                <>
                                                    {loanList.length === 0 ? (
                                                        <div className="text-center">
                                                            <h4>Please Choose the One Employee.</h4>
                                                        </div>
                                                    ) : (
                                                        loanList.map((loan, index) => (
                                                            <div style={{ width: "100%" }} className='bg-white p-1 rounded d-flex border gap-3 m-1' key={index}>
                                                                <div style={{ width: "80%" }} className='pt-4'>
                                                                    <h5 className='text-dark fw-bold'>
                                                                        Loan Number: <span className='text-dark fw-normal'>{loan.loanNumber}</span>
                                                                    </h5>
                                                                    <hr />
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div>
                                                                            <h6>Department: {loan.departmentName}</h6>
                                                                            <h6>Name: {loan.employeeName}</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Loan Number: {loan.loanNumber}</h6>
                                                                            <h6>Loan Amount: {loan.loanAmount}</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Loan Date: {new Date(loan.loanDate).toLocaleDateString()}</h6>
                                                                            <h6>Loan Type: {loan.loanFor}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: "20%" }} className='d-flex flex-column gap-2 p-1'>
                                                                    <button className="btn btn-outline-success" onClick={() => handleAddRepayment(loan)}>Add Repayment</button>
                                                                    <button className="btn btn-outline-primary" onClick={() => handleViewRepayment(loan)}>View Repayment</button>
                                                                    <div className='d-flex  gap-2'>
                                                                        <button className="btn btn-outline-primary" onClick={() => handleViewLoanDetails(loan)}>View</button>
                                                                        <button className="btn btn-outline-success" onClick={() => handleEditLoanList(loan)}>
                                                                            <i className="fas fa-edit"></i>
                                                                        </button>
                                                                        <button className="btn btn-outline-danger" onClick={() => handleDeleteLoan(loan)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                </>
                                            )}
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div className='from-row bg-light py-2 my-2 rounded border border-1'>
                                <div className='col-sm-12 col-md-12'>
                                    <div className='panel panel-bd'>
                                        <div className='panel-heading'>
                                            <div className='panel-title'>
                                                <h5 className='p-1 text-black fw-bolder'>Repayment Details</h5>
                                            </div>
                                        </div>
                                        <div className='panel-body'>
                                            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">

                                                        <ThreeDots
                                                            color="#00BFFF"
                                                            height={80}
                                                            width={80}
                                                        />
                                                    </div>
                                                ) : (
                                                    <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Employee Name</th>
                                                                <th>Loan Number</th>
                                                                <th>Loan Amount</th>
                                                                <th>Repayment Amount</th>
                                                                <th>Repayment Date</th>
                                                                <th>Repayment mode</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {repaymentDetails.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">Thier is No Repayment Of That Employee.</td>
                                                                </tr>
                                                            ) : (
                                                                repaymentDetails.map((repayment) => (
                                                                    <tr key={repayment.loanRepaymentId}>
                                                                        <td>{repayment.employeeName}</td>
                                                                        <td>{repayment.loanNumber}</td>
                                                                        <td>{repayment.loanAmount}</td>
                                                                        <td>{repayment.repaymentAmount}</td>
                                                                        <td>{new Date(repayment.repaymentDate).toLocaleDateString()}</td>
                                                                        <td>{repayment.repaymentMode}</td>

                                                                    </tr>
                                                                )))}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AddLoanRepayment modal */}
                    {showAddRepaymentModal && selectedLoan && (
                        <AddLoanRepayment
                            onClose={() => setShowAddRepaymentModal(false)}
                            onUpdate={handleUpdate}
                            employee={loanList}
                            loan={selectedLoan}
                        />
                    )}
                    {showLoanDetailsModal && selectedViewLoan && (
                        <LoanDetailsModal
                            onClose={() => setShowLoanDetailsModal(false)}
                            onUpdate={handleUpdate}
                            employee={loanList}
                            loan={selectedViewLoan}
                        />
                    )}
                    {isAddLoanModalOpen && <AddLoan onClose={handleCloseLoanModal} onUpdate={handleUpdate} />}
                    {isEditModalOpen && <EditLoanList loan={editLoanList} onClose={handleCloseEditModal} onUpdate={handleUpdate} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteLoanList ? deleteLoanList.loanNumber : ""}
                        onDelete={confirmdeleteLoanList}
                        onClose={handleCloseLoan}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
};

export default LoanList;
