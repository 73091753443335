import axios from 'axios';
import React, { useState, useEffect } from 'react';
import myImage from '../../images/employee_profile.png';

const LoanDetailsModal = ({ onClose, loan }) => {
    const employeeId = loan.employeeId;
    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        console.log("loan", loan);
    }, [loan]);

    const handleClose = () => {
        onClose();
    };
    useEffect(() => {
        fetchEmployeeDetails();
    }, []);

    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employeeloan/${employeeId}`);
            // console.log('Fetched Employee Details :', response.data);
            const employeeData = response.data.length > 0 ? response.data[0] : null;
            setEmployees(employeeData);
        } catch (error) {
            console.error('Error fetching Employee Details:', error);
        }
    };

    // Format loan date and repayment date to display in the desired format
    const formattedLoanDate = new Date(loan.loanDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });

    const formattedRepaymentDate = new Date(loan.loanRepaymentDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });


    return (
        <div id="addRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Loan Details</h4>
                        <button type="button" className="close" onClick={handleClose}>&times;</button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <h5 className='text-black fw-bold'>Employee Details</h5>
                        <div className=' d-flex align-items-center justify-content-between'>
                            <div>
                                <p><strong >Name:</strong> {loan.employeeName}</p>
                                <p><strong >Department:</strong> {loan.departmentName}</p>
                                <p><strong >Email:</strong> {employees.employeeEmail}</p>
                                <p><strong >Phone:</strong> {employees.employeePhone}</p>
                            </div>
                            <div className="">
                                <img
                                    src={employees.passportSizePhoto
                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employees.passportSizePhoto}`
                                        : myImage}
                                    style={{ width: "200px" }} className="employee-image"
                                    alt="Employee"
                                />
                            </div>
                        </div>
                        <h5 className='text-black fw-bold'>Loan Details</h5>
                        <div className=' d-flex p-1  justify-content-between'>
                            <div>
                                <p><strong >Loan Number:</strong> {loan.loanNumber}</p>
                                <p><strong >Amount:</strong> {loan.loanAmount}</p>
                                <p><strong >Date:</strong> {formattedLoanDate}</p>
                                <p><strong >Repayment Date:</strong> {formattedRepaymentDate}</p>
                                <p><strong >Repay Type:</strong> {loan.loanRepayType}</p>
                            </div>
                            <div>

                                <p><strong >Approved By:</strong> {loan.loanApprovedByName}</p>
                                <p><strong >Loan For:</strong> {loan.loanFor}</p>
                                <p><strong >Description:</strong> {loan.loanDescription}</p>
                                <p><strong >Remark:</strong> {loan.remark}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoanDetailsModal;
