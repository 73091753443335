// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SearchBar from '../../components/sidebar/SearchBar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import myImage from '../../images/employee_profile.png';
// import { ThreeDots } from 'react-loader-spinner';

// const AddAttendance = ({ handleLogout, username }) => {
//     const [isLoading, setIsLoading] = useState(false);
//     const [departments, setDepartments] = useState([]);
//     const [employees, setEmployees] = useState([]);
//     const [employeesDetails, setEmployeesDetails] = useState([]);
//     const [selectedDepartment, setSelectedDepartment] = useState('');
//     const [selectedEmployee, setSelectedEmployee] = useState('');
//     const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
//     const [status, setStatus] = useState('');
//     const [attendanceRecords, setAttendanceRecords] = useState([]);
//     const [error, setError] = useState('');
//     const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month
//     const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year

//     useEffect(() => {
//         fetchDepartments();
//     }, []);

//     useEffect(() => {
//         if (selectedDepartment) {
//             fetchEmployees(selectedDepartment);
//         }
//     }, [selectedDepartment]);

//     useEffect(() => {
//         if (selectedEmployee) {
//             fetchAttendanceRecords();
//             fetchEmployeesDetails()
//         }
//     }, [selectedEmployee, selectedMonth, selectedYear]);

//     const fetchDepartments = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
//             setDepartments(response.data);
//         } catch (error) {
//             console.error('Error fetching departments:', error);
//         }
//     };

//     const fetchEmployees = async (departmentId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };

//     const fetchEmployeesDetails = async () => {

//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${selectedEmployee}`);

//             // Assuming response.data is an array of employee objects
//             const employeeData = response.data;

//             // If you want to ensure you only process the first employee or handle multiple employees
//             if (Array.isArray(employeeData) && employeeData.length > 0) {
//                 // For example, set the first employee details
//                 setEmployeesDetails(employeeData[0]);
//             } else {
//                 setEmployeesDetails(null); // Or handle an empty array case as needed
//             }

//             console.log("empde", employeeData);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };


//     const fetchAttendanceRecords = async () => {
//         setIsLoading(true);
//         try {
//             const attendanceResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${selectedEmployee}`, {
//                 params: {
//                     month: selectedMonth,
//                     year: selectedYear
//                 }
//             });

//             const attendanceData = attendanceResponse.data || [];

//             // Filter records to include only those within the selected month and year
//             const startDate = new Date(selectedYear, selectedMonth - 1, 1).toISOString();
//             const endDate = new Date(selectedYear, selectedMonth, 0, 23, 59, 59).toISOString();

//             const filteredData = attendanceData.filter(record => {
//                 const recordDate = new Date(record.date);
//                 return recordDate >= new Date(startDate) && recordDate <= new Date(endDate);
//             });

//             const combinedData = filteredData.map(attendance => {
//                 const employee = employees.find(emp => emp.id === attendance.employee_id);
//                 return {
//                     ...attendance,
//                     employeeName: employee ? employee.employeeName : 'Unknown',
//                     passportSizePhoto: employee ? employee.passportSizePhoto : 'default.png'
//                 };
//             });

//             setAttendanceRecords(combinedData);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsLoading(true);

//         const requiredFields = ['selectedDepartment', 'selectedEmployee', 'date', 'status'];
//         for (const field of requiredFields) {
//             if (!eval(field)) {
//                 setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
//                 return;
//             }
//         }
//         setError('');
//         try {
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/attendance`, {
//                 employeeId: selectedEmployee,
//                 employeeCode: employeesDetails.employeeCode,
//                 date,
//                 status
//             });
//             fetchAttendanceRecords(); // Refresh attendance records after submission
//             toast.success('Attendance recorded successfully');
//         } catch (error) {
//             console.error('Error saving attendance:', error);
//             toast.error('Failed to record attendance');
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const totals = {
//         totalPresent: attendanceRecords.filter(record => record.status === 'present').length,
//         totalAbsent: attendanceRecords.filter(record => record.status === 'absent').length,
//         totalHalfDay: attendanceRecords.filter(record => record.status === 'half day').length,
//         totalWeeklyOff: attendanceRecords.filter(record => record.status === 'weekly off').length,
//         totalUnpaidLeave: attendanceRecords.filter(record => record.status === 'unpaid leave').length,
//         totalPaidLeave: attendanceRecords.filter(record => record.status === 'paid leave').length,
//         totalOvertime: attendanceRecords.filter(record => record.status === 'overtime').length
//     };

//     const getDayOfWeek = (dateString) => {
//         const date = new Date(dateString);
//         const options = { weekday: 'long' };
//         return new Intl.DateTimeFormat('en-US', options).format(date);
//     };
//     // Function to get row class based on status
//     const getRowClass = (status) => {
//         switch (status) {
//             case 'present':
//                 return 'table-success'; // Green color for success
//             case 'absent':
//                 return 'table-danger'; // Red color for danger
//             case 'half day':
//                 return 'table-info';   // Light blue color for info
//             case 'overtime':
//                 return 'table-primary'; // Blue color for primary
//             case 'weekly off':
//             case 'unpaid leave':
//             case 'paid leave':
//                 return 'table-warning'; // Yellow color for warning
//             default:
//                 return '';
//         }
//     };

//     useEffect(() => {
//         // Update the date to reflect the selected month and year
//         const newDate = new Date(selectedYear, selectedMonth - 1, new Date(date).getDate());
//         setDate(newDate.toISOString().split('T')[0]);
//     }, [selectedMonth, selectedYear]);

//     return (
//         <div className='d-flex w-100 h-100 bg-white '>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     <div className="se-pre-con" style={{ display: 'none' }}></div>
//                     <div className="row">
//                         <div className="col-sm-12 col-md-12 shadow bg-white px-2 py-3 rounded">
//                             <div className="panel panel-bd">
//                                 <div className="panel-heading">
//                                     <div className="panel-title">
//                                         <h4 style={{ color: "#00509d" }} className='p-1 fw-bolder'> Add Attendance</h4>
//                                     </div>
//                                 </div>
//                                 <div className="panel-body" style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto", overflowX: "hidden" }}>
//                                     <form onSubmit={handleSubmit} className="form-inne bg-white p-2 rounded" encType="multipart/form-data" acceptCharset="utf-8">
//                                         {error && <div className="alert alert-danger">{error}</div>}
//                                         <div className="form-row">
//                                             <div className='form-group col-md-12 bg-light border d-flex align-items-center justify-content-between px-4 py-2 rounded '>
//                                                 <div className="form-group col-md-4 m-0 ">
//                                                     <div className="col-xs-9">
//                                                         <select className="form-control" id="department" value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)}>
//                                                             <option value="">Select Department</option>
//                                                             {departments.map(dept => (
//                                                                 <option key={dept.id} value={dept.id}>{dept.name}</option>
//                                                             ))}
//                                                         </select>
//                                                     </div>
//                                                 </div>
//                                                 <div className="form-group col-md-4  m-0">
//                                                     <div className="col-xs-9">
//                                                         <select className="form-control" id="employee" value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}>
//                                                             <option value="">Select Employee</option>
//                                                             {employees.map(emp => (
//                                                                 <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
//                                                             ))}
//                                                         </select>
//                                                     </div>
//                                                 </div>
//                                                 <div className='form-group col-md-4 d-flex align-items-center justify-content-center gap-1 m-0'>
//                                                     <label className='pt-2 text-black fw-bolder'>Filter:</label>
//                                                     <select className="form-control" value={selectedMonth}
//                                                         onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
//                                                     >
//                                                         <option value="">Month</option>
//                                                         {Array.from({ length: 12 }, (_, i) => (
//                                                             <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
//                                                         ))}
//                                                     </select>
//                                                     <select className="form-control" value={selectedYear}
//                                                         onChange={(e) => setSelectedYear(parseInt(e.target.value))}
//                                                     >
//                                                         <option value="">Select Year</option>
//                                                         {Array.from({ length: 10 }, (_, i) => (
//                                                             <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
//                                                         ))}
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className='form-group col-md-12 bg-light border d-flex align-items-center justify-content-between px-4 py-1 m-0 rounded'>
//                                                 <div className="form-group col-md-4 d-flex align-items-start m-0">
//                                                     <div className='d-flex align-items-center gap-2'>
//                                                         <label htmlFor="date" className="col-xs-3 mt-1 col-form-label">Date:<span style={{ color: "red" }}>*</span></label>

//                                                         <div className='p-1 text-dark fw-bold'>{getDayOfWeek(date)}</div>
//                                                         <input type="date" className="form-control" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
//                                                     </div>
//                                                 </div>
//                                                 <div className="form-group col-md-7 m-0">
//                                                     <div className="d-flex flex-column align-items-center justify-content-center gap-1">
//                                                         <div className='d-flex gap-1'>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'present' ? 'success' : 'default'}`} onClick={() => setStatus('present')}>Present</button>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'paid leave' ? 'warning' : 'default'}`} onClick={() => setStatus('paid leave')}>Paid Leave</button>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'overtime' ? 'primary' : 'default'}`} onClick={() => setStatus('overtime')}>Overtime</button>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'weekly off' ? 'warning' : 'default'}`} onClick={() => setStatus('weekly off')}>Weekly Off</button>
//                                                         </div>
//                                                         <div className='d-flex gap-1'>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'absent' ? 'danger' : 'default'}`} onClick={() => setStatus('absent')}>Absent</button>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'half day' ? 'info' : 'default'}`} onClick={() => setStatus('half day')}>Half Day</button>
//                                                             <button type="button" className={`border border-4  btn btn-${status === 'unpaid leave' ? 'warning' : 'default'}`} onClick={() => setStatus('unpaid leave')}>Unpaid Leave</button>
//                                                         </div>

//                                                     </div>
//                                                 </div>
//                                                 <button type="submit" className="btn btn-primary" disabled={isLoading}>
//                                                     {isLoading ? 'Loading...' : 'Submit'}
//                                                 </button>
//                                             </div>

//                                         </div>
//                                     </form>
//                                     <div className="form-row p-2 rounded">
//                                         <div className=' form-group bg-light border shadow-sm rounded col-md-3 px-5'>
//                                             <h5 className='text-center p-1 text-black fw-bolder'>Total Attendance</h5>
//                                             <div className="d-flex flex-column gap-2 justify-content-around mb-3">
//                                                 <button className="btn btn-success text-white p-2 border rounded">Present: {totals.totalPresent}</button>
//                                                 <button className="btn btn-warning text-white p-2 border rounded">Weekly Off: {totals.totalWeeklyOff}</button>
//                                                 <button className="btn btn-warning text-white p-2 border rounded">Paid Leave: {totals.totalPaidLeave}</button>
//                                                 <button className="btn btn-primary text-white p-2 border rounded">Overtime: {totals.totalOvertime}</button>
//                                                 <button className="btn btn-danger text-white p-2 border rounded">Absent: {totals.totalAbsent}</button>
//                                                 <button className="btn btn-info text-white p-2 border rounded">Half Day: {totals.totalHalfDay}</button>

//                                                 <button className="btn btn-warning text-white p-2 border rounded">Unpaid Leave: {totals.totalUnpaidLeave}</button>

//                                                 <button className="btn btn-primary text-white p-2 border rounded">Total Attendance: {totals.totalPaidLeave + totals.totalUnpaidLeave + totals.totalWeeklyOff + totals.totalHalfDay + totals.totalAbsent + totals.totalPresent + totals.totalOvertime}</button>
//                                             </div>
//                                         </div>
//                                         <div className='form-group col-md-9 border shadow-sm rounded bg-light ' style={{ maxHeight: "450px", overflowY: "auto" }}>
//                                             <table className="table table-bordered" style={{ width: "100%" }}>
//                                                 <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                     <tr>
//                                                         <th>Employee</th>
//                                                         <th>Date</th>
//                                                         <th>Status</th>
//                                                         <th>Day of Week</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {attendanceRecords.length === 0 ? (
//                                                         <tr>
//                                                             <td colSpan="5" className="text-center">No Attendance Found First Select the Employee.</td>
//                                                         </tr>
//                                                     ) : (attendanceRecords.map((record, index) => (
//                                                         <tr key={index} className={getRowClass(record.status)}>
//                                                             <td className="d-flex align-items-center">
//                                                                 <img
//                                                                     src={record.passportSizePhoto ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}` : myImage}
//                                                                     alt="Employee"
//                                                                     className="rounded-circle"
//                                                                     width="30"
//                                                                     height="30"
//                                                                 />
//                                                                 <span className="ms-2">{record.employeeName}</span>
//                                                             </td>
//                                                             <td>{new Date(record.date).toLocaleDateString()}</td>
//                                                             <td>{record.status}</td>
//                                                             <td>{getDayOfWeek(record.date)}</td>
//                                                         </tr>)
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddAttendance;











import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';
import myImage from '../../images/employee_profile.png';
import { ThreeDots } from 'react-loader-spinner';

const AddAttendance = ({ handleLogout, username }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeesDetails, setEmployeesDetails] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [status, setStatus] = useState('');
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [error, setError] = useState('');
    const [overtimeHours, setOvertimeHours] = useState(''); // State for overtime hours
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        if (selectedDepartment) {
            fetchEmployees(selectedDepartment);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedEmployee) {
            fetchAttendanceRecords();
            fetchEmployeesDetails()
        }
    }, [selectedEmployee, selectedMonth, selectedYear]);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchEmployeesDetails = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${selectedEmployee}`);

            // Assuming response.data is an array of employee objects
            const employeeData = response.data;

            // If you want to ensure you only process the first employee or handle multiple employees
            if (Array.isArray(employeeData) && employeeData.length > 0) {
                // For example, set the first employee details
                setEmployeesDetails(employeeData[0]);
            } else {
                setEmployeesDetails(null); // Or handle an empty array case as needed
            }

            console.log("empde", employeeData);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };


    const fetchAttendanceRecords = async () => {
        setIsLoading(true);
        try {
            const attendanceResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${selectedEmployee}`, {
                params: {
                    month: selectedMonth,
                    year: selectedYear
                }
            });

            const attendanceData = attendanceResponse.data || [];

            // Filter records to include only those within the selected month and year
            const startDate = new Date(selectedYear, selectedMonth - 1, 1).toISOString();
            const endDate = new Date(selectedYear, selectedMonth, 0, 23, 59, 59).toISOString();

            const filteredData = attendanceData.filter(record => {
                const recordDate = new Date(record.date);
                return recordDate >= new Date(startDate) && recordDate <= new Date(endDate);
            });

            const combinedData = filteredData.map(attendance => {
                const employee = employees.find(emp => emp.id === attendance.employee_id);
                return {
                    ...attendance,
                    employeeName: employee ? employee.employeeName : 'Unknown',
                    passportSizePhoto: employee ? employee.passportSizePhoto : 'default.png'
                };
            });

            setAttendanceRecords(combinedData);
        } catch (error) {
            console.error("Error fetching combined data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['selectedDepartment', 'selectedEmployee', 'date', 'status'];
        for (const field of requiredFields) {
            if (!eval(field)) {
                setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
                return;
            }
        }
        setError('');
        setIsLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/attendance`, {
                employeeId: selectedEmployee,
                employeeCode: employeesDetails.employeeCode,
                date,
                status,
                overtimeHours: status === 'overtime' ? overtimeHours : 0 // Include overtime hours if status is 'overtime'
            });
            fetchAttendanceRecords(); // Refresh attendance records after submission
            toast.success('Attendance recorded successfully');
        } catch (error) {
            console.error('Error saving attendance:', error);
            toast.error('Failed to record attendance');
        } finally {
            setIsLoading(false);
        }
    };

    const totals = {
        totalPresent: attendanceRecords.filter(record => record.status === 'present').length,
        totalAbsent: attendanceRecords.filter(record => record.status === 'absent').length,
        totalHalfDay: attendanceRecords.filter(record => record.status === 'half day').length,
        totalWeeklyOff: attendanceRecords.filter(record => record.status === 'weekly off').length,
        totalUnpaidLeave: attendanceRecords.filter(record => record.status === 'unpaid leave').length,
        totalPaidLeave: attendanceRecords.filter(record => record.status === 'paid leave').length,
        totalOvertime: attendanceRecords.filter(record => record.status === 'overtime').length
    };

    const getDayOfWeek = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'long' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    // Function to get row class based on status
    const getRowClass = (status) => {
        switch (status) {
            case 'present':
                return 'table-success'; // Green color for success
            case 'absent':
                return 'table-danger'; // Red color for danger
            case 'half day':
                return 'table-info';   // Light blue color for info
            case 'overtime':
                return 'table-primary'; // Blue color for primary
            case 'weekly off':
            case 'unpaid leave':
            case 'paid leave':
                return 'table-warning'; // Yellow color for warning
            default:
                return '';
        }
    };

    useEffect(() => {
        // Update the date to reflect the selected month and year
        const newDate = new Date(selectedYear, selectedMonth - 1, new Date(date).getDate());
        setDate(newDate.toISOString().split('T')[0]);
    }, [selectedMonth, selectedYear]);

    return (
        <div className='d-flex w-100 h-100 bg-white '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="se-pre-con" style={{ display: 'none' }}></div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 shadow bg-white px-2 py-3 rounded">
                            <div className="panel panel-bd">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <h4 style={{ color: "#00509d" }} className='p-1 fw-bolder'> Add Attendance</h4>
                                    </div>
                                </div>
                                <div className="panel-body" style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto", overflowX: "hidden" }}>
                                    <form onSubmit={handleSubmit} className="form-inne bg-white p-2 rounded" encType="multipart/form-data" acceptCharset="utf-8">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <div className="form-row">
                                            <div className='form-group col-md-12 bg-light border d-flex align-items-center justify-content-between px-4 py-2 rounded '>
                                                <div className="form-group col-md-4 m-0 ">
                                                    <div className="col-xs-9">
                                                        <select className="form-control" id="department" value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)}>
                                                            <option value="">Select Department</option>
                                                            {departments.map(dept => (
                                                                <option key={dept.id} value={dept.id}>{dept.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-4  m-0">
                                                    <div className="col-xs-9">
                                                        <select className="form-control" id="employee" value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}>
                                                            <option value="">Select Employee</option>
                                                            {employees.map(emp => (
                                                                <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='form-group col-md-4 d-flex align-items-center justify-content-center gap-1 m-0'>
                                                    <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                                    <select className="form-control" value={selectedMonth}
                                                        onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                                    >
                                                        <option value="">Month</option>
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                        ))}
                                                    </select>
                                                    <select className="form-control" value={selectedYear}
                                                        onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                                    >
                                                        <option value="">Select Year</option>
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-12 bg-light border d-flex align-items-center justify-content-between px-4 py-1 m-0 rounded'>
                                                <div className="form-group col-md-4 d-flex align-items-start m-0">
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <label htmlFor="date" className="col-xs-3 mt-1 col-form-label">Date:<span style={{ color: "red" }}>*</span></label>

                                                        <div className='p-1 text-dark fw-bold'>{getDayOfWeek(date)}</div>
                                                        <input type="date" className="form-control" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-7 m-0">
                                                    <div className=' d-flex align-items-center justify-content-around'>
                                                        <div className="d-flex flex-column align-items-center justify-content-center gap-1">
                                                            <div className='d-flex gap-1'>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'present' ? 'success' : 'default'}`} onClick={() => setStatus('present')}>Present</button>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'paid leave' ? 'warning' : 'default'}`} onClick={() => setStatus('paid leave')}>Paid Leave</button>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'overtime' ? 'primary' : 'default'}`} onClick={() => setStatus('overtime')}>Overtime</button>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'weekly off' ? 'warning' : 'default'}`} onClick={() => setStatus('weekly off')}>Weekly Off</button>
                                                            </div>
                                                            <div className='d-flex gap-1'>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'absent' ? 'danger' : 'default'}`} onClick={() => setStatus('absent')}>Absent</button>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'half day' ? 'info' : 'default'}`} onClick={() => setStatus('half day')}>Half Day</button>
                                                                <button type="button" className={`border border-4  btn btn-${status === 'unpaid leave' ? 'warning' : 'default'}`} onClick={() => setStatus('unpaid leave')}>Unpaid Leave</button>
                                                            </div> 
                                                        </div>
                                                        <div className=''>
                                                        {status === 'overtime' && (
                                                                <div className="mt-2">
                                                                    <label htmlFor="overtimeHours">Overtime Hours:</label>
                                                                    <select
                                                                        id="overtimeHours"
                                                                        className="form-control"
                                                                        value={overtimeHours}
                                                                        onChange={(e) => setOvertimeHours(e.target.value)}
                                                                    >
                                                                        <option value="">Select hours</option>
                                                                        {Array.from({ length: 8 }, (_, i) => i + 1).map(hour => (
                                                                            <option key={hour} value={hour}>{hour} {hour === 1 ? 'Hour' : 'Hours'}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                    <div className="form-row p-2 rounded">
                                        <div className=' form-group bg-light border shadow-sm rounded col-md-3 px-5'>
                                            <h5 className='text-center p-1 text-black fw-bolder'>Total Attendance</h5>
                                            <div className="d-flex flex-column gap-2 justify-content-around mb-3">
                                                <button className="btn btn-success text-white p-2 border rounded">Present: {totals.totalPresent}</button>
                                                <button className="btn btn-warning text-white p-2 border rounded">Weekly Off: {totals.totalWeeklyOff}</button>
                                                <button className="btn btn-warning text-white p-2 border rounded">Paid Leave: {totals.totalPaidLeave}</button>
                                                <button className="btn btn-primary text-white p-2 border rounded">Overtime: {totals.totalOvertime}</button>
                                                <button className="btn btn-danger text-white p-2 border rounded">Absent: {totals.totalAbsent}</button>
                                                <button className="btn btn-info text-white p-2 border rounded">Half Day: {totals.totalHalfDay}</button>

                                                <button className="btn btn-warning text-white p-2 border rounded">Unpaid Leave: {totals.totalUnpaidLeave}</button>

                                                <button className="btn btn-primary text-white p-2 border rounded">Total Attendance: {totals.totalPaidLeave + totals.totalUnpaidLeave + totals.totalWeeklyOff + totals.totalHalfDay + totals.totalAbsent + totals.totalPresent + totals.totalOvertime}</button>
                                            </div>
                                        </div>
                                        <div className='form-group col-md-9 border shadow-sm rounded bg-light ' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Employee</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th>Day of Week</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {attendanceRecords.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">No Attendance Found First Select the Employee.</td>
                                                        </tr>
                                                    ) : (attendanceRecords.map((record, index) => (
                                                        <tr key={index} className={getRowClass(record.status)}>
                                                            <td className="d-flex align-items-center">
                                                                <img
                                                                    src={record.passportSizePhoto ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}` : myImage}
                                                                    alt="Employee"
                                                                    className="rounded-circle"
                                                                    width="30"
                                                                    height="30"
                                                                />
                                                                <span className="ms-2">{record.employeeName}</span>
                                                            </td>
                                                            <td>{new Date(record.date).toLocaleDateString()}</td>
                                                            <td>{record.status}</td>
                                                            <td>{getDayOfWeek(record.date)}</td>
                                                        </tr>)
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAttendance;
