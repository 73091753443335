// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import myLogo from '../images/salary.jpg';

// const SignUpHRM = () => {
//   const [dashboardLogo, setDashboardLogo] = useState([]);
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//     userType: 'user', // default value
//   });

//   const navigate = useNavigate();
//   useEffect(() => {
//     const fetchDashboardLogo = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//         setDashboardLogo(response.data);
//       } catch (error) {
//         console.error('Error fetching Dashboard Logo', error);
//       }
//     };

//     fetchDashboardLogo();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch(`${process.env.REACT_APP_LOCAL_URL}/signup`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });

//       const data = await response.json();

//       // Adjust the success condition based on the actual response structure
//       if (response.ok && data.message === 'User created successfully') {
//         toast.success('Signup successful!');
//         setTimeout(() => {
//           navigate('/');
//         }, 1000); // 1 second delay
//         // Navigate to the desired page after successful signup
//       } else if (data.error === 'User already exists') {
//         toast.error('User already registered!');
//       } else {
//         toast.error('Failed to create details! Please ensure all fields are correctly filled.');
//       }
//     } catch (error) {
//       toast.error('An error occurred. Please try again.');
//     }
//   };

//   const handleForgotPassword = () => {
//     navigate('/forgot-password'); // Adjust the route to your forgot password page
//   };

//   return (<div className="container-fluid d-flex flex-column justify-content-between align-items-center bg-white" style={{ minHeight: '100vh' }}>
//     <ToastContainer /> {/* Toast container */}
//     <nav className="login-logo">
//       <div style={{ width: "100%", height: "100%" }} className=''>
//         <img
//           src={dashboardLogo.landingPageLogo
//             ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
//             : myLogo}
//           className='img-login-logo'
//           alt="LOGO"
//         />
//       </div>
//     </nav>
//     <h1 className="text-black ml-3">HRM SOFTWARE</h1>
//     <div className="d-flex flex-column justify-content-between align-items-center gap-3 w-25">
//       <div className='bg-white p-3 rounded shadow-sm w-100' >
//         <div>
//           <h2 className='text-center text-black fw-bolder'>Signup</h2>
//           <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
//             <div className="form-group">
//               <label className='text-black' htmlFor="username">Username</label>
//               <input
//                 type="text"
//                 id="username"
//                 name="username"
//                 value={formData.username}
//                 onChange={handleChange}
//                 required
//                 placeholder='Username'
//                 className='form-control'
//               />
//             </div>
//             <div className="form-group">
//               <label className='text-black' htmlFor="email">Email</label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//                 placeholder='Email'
//                 className='form-control'
//               />
//             </div>
//             <div className="form-group">
//               <label className='text-black' htmlFor="password">Password</label>
//               <input
//                 type="password"
//                 id="password"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 required
//                 placeholder='Password'
//                 className='form-control'
//               />
//             </div>
//             <div className="form-group">
//               <label className='text-black' htmlFor="userType">userType</label>
//               <select
//                 id="userType"
//                 name="userType"
//                 value={formData.userType}
//                 onChange={handleChange}
//                 required
//                 className='form-control'
//               >
//                 <option value="user">User</option>
//                 <option value="manager">Manager</option>
//                 <option value="admin">Admin</option>
//               </select>
//             </div>
//             <div className='text-center'>
//               <button type="submit" className="btn btn-primary">Signup</button>
//             </div>
//           </form>
//           <Link onClick={handleForgotPassword} className="forgot-password text-center">Forgot Password?</Link>
//         </div>
//       </div>
//       <footer className='p-2'>
//         Version 1.0 &copy; Developed by Prospect Digital
//       </footer>
//     </div>
//   </div>);
// };

// export default SignUpHRM;




// import React, { useState, useEffect } from 'react';
// import { Container, Paper, Typography, TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
// import { useNavigate, Link } from 'react-router-dom';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import logo from '../images/salary.jpg';


// const SignUpHRM = () => {
//   const [dashboardLogo, setDashboardLogo] = useState([]);
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//     userType: 'user', // default value
//   });

//   const navigate = useNavigate();
//   useEffect(() => {
//     const fetchDashboardLogo = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//         setDashboardLogo(response.data);
//       } catch (error) {
//         console.error('Error fetching Dashboard Logo', error);
//       }
//     };

//     fetchDashboardLogo();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch(`${process.env.REACT_APP_LOCAL_URL}/signup`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });

//       const data = await response.json();

//       // Adjust the success condition based on the actual response structure
//       if (response.ok && data.message === 'User created successfully') {
//         toast.success('Signup successful!');
//         setTimeout(() => {
//           navigate('/');
//         }, 1000); // 1 second delay
//         // Navigate to the desired page after successful signup
//       } else if (data.error === 'User already exists') {
//         toast.error('User already registered!');
//       } else {
//         toast.error('Failed to create details! Please ensure all fields are correctly filled.');
//       }
//     } catch (error) {
//       toast.error('An error occurred. Please try again.');
//     }
//   };

//   const handleForgotPassword = () => {
//     navigate('/forgotpassword');
//   };


//   return (
//     <div className="container-fluid d-flex flex-column justify-content-between align-items-center bg-white" style={{ minHeight: '100vh' }}>
//       <ToastContainer /> {/* Toast container */}
//       <nav className="login-logo">
//         <div style={{ width: "100%", height: "100%" }} className=''>
//           <img
//             src={dashboardLogo.landingPageLogo
//               ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
//               : logo}
//             className='img-login-logo'
//             alt="LOGO"
//           />
//         </div>
//       </nav>

//       <Container className="d-flex flex-column justify-content-center align-items-center gap-3">
//         <h3 className="text-black ml-3 fw-bolder">HRM SOFTWARE</h3>
//         <Paper className="login-paper p-4" style={{ borderRadius: '20px' }} elevation={3}>
//           <Typography component="h1" variant="h5" style={{ textAlign: 'center' }}>
//             Sign Up
//           </Typography>
//           <form className="login-form" onSubmit={handleSubmit}>
//             <TextField
//               type="text"
//               id="username"
//               name="username"
//               value={formData.username}
//               onChange={handleChange}
//               required
//               placeholder="Username"
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               label="Username"
//               autoComplete="username"
//               autoFocus
//             />
//             <TextField
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//               placeholder="Email"
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               label="Email Address"
//               autoComplete="email"
//             />
//             <TextField
//               type="password"
//               id="password"
//               name="password"
//               value={formData.password}
//               onChange={handleChange}
//               required
//               placeholder="Password"
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               label="Password"
//               autoComplete="current-password"
//             />
//             <FormControl fullWidth margin="normal" variant="outlined">
//               <InputLabel id="userType-label">User Type</InputLabel>
//               <Select
//                 labelId="userType-label"
//                 id="userType"
//                 name="userType"
//                 value={formData.userType}
//                 onChange={handleChange}
//                 label="User Type"
//               >
//                 <MenuItem value="user">User</MenuItem>
//                 <MenuItem value="manager">Manager</MenuItem>
//                 <MenuItem value="admin">Admin</MenuItem>
//               </Select>
//             </FormControl>
//             <Button type="submit" fullWidth variant="contained" color="primary" className="login-button">
//               Sign Up
//             </Button>
//             <Typography variant="body2" className="text-center m-4">
//               <Link onClick={handleForgotPassword} className="signup-link" style={{ cursor: 'pointer' }}>
//                 Forgot password?
//               </Link>
//             </Typography>
//           </form>
//         </Paper>
//       </Container>
//       <footer className='p-2'>
//         Version 1.0 &copy; Developed by Prospect Digital
//       </footer>
//     </div>
//   );
// };

// export default SignUpHRM;









import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
// import './SignUpHRM.css'; // Import your external CSS file
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import myLogo from '../images/salary.jpg';
import favicon from '../images/salary_logo.png';
import loginimg from '../images/hrm.gif';


const SignUpHRM = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardLogo, setDashboardLogo] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    userType: 'admin', // default value
  });


  useEffect(() => {
    const fetchDashboardLogo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setDashboardLogo(response.data);
      } catch (error) {
        console.error('Error fetching Dashboard Logo', error);
      }
    };

    fetchDashboardLogo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_LOCAL_URL}/signup`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     const data = await response.json();

  //     // Adjust the success condition based on the actual response structure
  //     if (response.ok && data.message === 'User created successfully') {
  //       toast.success('Signup successful!');
  //       setTimeout(() => {
  //         navigate('/');
  //       }, 1000); // 1 second delay
  //       // Navigate to the desired page after successful signup
  //     } else if (data.error === 'User already exists') {
  //       toast.error('User already registered!');
  //     } else {
  //       toast.error('Failed to create details! Please ensure all fields are correctly filled.');
  //     }
  //   } catch (error) {
  //     toast.error('An error occurred. Please try again.');
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok && data.message === 'User created successfully') {
        toast.success('Signup successful!');
        setTimeout(() => {
          navigate('/');
        }, 1000); // 1 second delay
      } else if (data.error === 'Admin already exists') {
        // If admin already exists, show an alert and redirect to the forgot password page
        alert('Admin already registered. Redirecting to forgot password...');
        navigate('/forgotpassword');
      } else if (data.error === 'User already exists') {
        toast.error('User already registered!');
      } else {
        toast.error('Failed to create details! Please ensure all fields are correctly filled.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div style={{ backgroundColor: "#E8FBFF" }} className='login-container '>
      <div className='main-container shadow-sm'>
        <ToastContainer /> {/* Toast container */}
        <div className='login-right d-flex align-item-end justify-content-end'>
          <img className='loginimage' src={loginimg} alt="Background" />
          <div className='right-div'>
            <img className='login-favicon' src={favicon} alt="Background" />
            <div>
              <h4 className='fw-bolder'>HRM Software</h4>
              <p className='fw-bold'>Simplify HR Operations with Comprehensive Tools for Payroll, Attendance, and Employee Records</p>
            </div>
          </div>
        </div>
        <div className='login-left'>
          <div className='header'>
            <nav className="signin_logo">
              <div style={{ width: "100%", height: "100%" }} className=''>
                <img
                  src={dashboardLogo.landingPageLogo
                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
                    : myLogo}
                  className='img-signin-logo'
                  alt="LOGO"
                />
              </div>
            </nav>
          </div>
          <div className='content d-flex flex-column align-items-center'>
            <div className='text-center p-2 heading'>
              <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder text-uppercase font-bold m-0">HRM Software.</h2>
              {/* <p className=''>Manage Teams Easily with Smart HR</p> */}
            </div>
            <div style={{ width: "70%", boxShadow: "2px 2px 10px black" }} className="d-flex flex-column justify-content-around align-items-center gap-3  p-4 rounded-3 text-center ">
              <h4 style={{ color: "#00509d" }} className="title-detail fw-bolder text-uppercase font-bold m-0"> Sign Up.</h4>
              <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                <div className="row">
                  <div className="form-group">
                    <input id="username"
                      name="username"
                      label="Username"
                      autoComplete="username"
                      autoFocus
                      type="text"
                      required
                      placeholder="Username" value={formData.username}
                      onChange={handleChange} className="form-control" />
                  </div>
                  <div className="form-group">
                    <input id="email" name="email"
                      autoComplete="email"
                      autoFocus
                      type="email"
                      label="Email Address"
                      placeholder="Email" value={formData.email}
                      onChange={handleChange} className="form-control" required />
                  </div>
                  <div className="form-group">
                    <input name="password" type="password" id="password" placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                      label="Password"
                      autoComplete="current-password"
                      className="form-control" required />
                  </div>
                  <div className="form-group">
                    <select id="userType"
                      name="userType"
                      value={formData.userType}
                      onChange={handleChange}
                      label="User Type" className="form-control" >
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                      {isLoading ? 'Loading...' : 'Sign Up'}
                    </button>
                  </div>
                  <hr className='m-1 p-0' />
                  <div className="d-flex align-items-center justify-content-center gap-3 ">
                    <Link style={{ cursor: 'pointer' }} to="/forgotpassword">
                      Forgot password?
                    </Link>
                    <Link style={{ cursor: 'pointer' }} to="/">
                      Signin
                    </Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <div className='footer'>
            <p className="text-center text-body-secondary">Version 1.0 &copy; Developed by Prospect Digital</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpHRM;

