import React, { useState, useEffect } from "react";
import axios from "axios";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import ActiveInactiveModal from "../EmployeeMaster/ActiveInactiveModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function Resign_TerminationList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
    const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editEmployee, setEditEmployee] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteEmployee, setDeleteEmployee] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [combineDetails, setCombineDetails] = useState(null);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    useEffect(() => {
        fetchCombinedData();
    }, []);

    const fetchCombinedData = async () => {
        setIsLoading(true);

        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive/resignterminate`);

            const activeInactiveData = activeInactiveResponse.data;

            const combinedData = employeesResponse.data
                .filter(employee => activeInactiveData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingActiveInactive = activeInactiveData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingActiveInactive || {}
                    };
                });

            setEmployees(combinedData);
            setFilteredLeaveRequests(combinedData.slice(0, itemsPerPage));
        } catch (error) {
            console.error("Error fetching combined data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };



    const handleAddEmployee = () => {
        setIsAddEmployeeModalOpen(true);
    };

    const handleCloseEmployeeModal = () => {
        setIsAddEmployeeModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleEmployeeDetails = (employee) => {
        setSelectedEmployee(employee);
        setShowEmployeeDetails(true);
    };

    const handleEditEmployeeClick = (employee) => {
        setEditEmployee(employee);
        setSelectedEmployee(employee);
        setIsEditModalOpen(true);
    };

    const handleEditEmployeeClose = () => {
        setSelectedEmployee(null);
    };

    const handleBackToTable = () => {
        setSelectedEmployee(null);
        setShowEmployeeDetails(false);
    };

    const handleDeleteEmployee = (employee) => {
        setDeleteEmployee(employee);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateEmployee = async (updatedEmployee) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employees/${updatedEmployee.id}`, updatedEmployee);
            console.log("Employee updated:", response.data);
            const updatedEmployees = employees.map(employee => (employee.id === updatedEmployee.id ? response.data : employee));
            setEmployees(updatedEmployees);
        } catch (error) {
            console.error("Error updating employee:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/employees/${deleteEmployee.id}`);

            const deletedEmployee = { ...deleteEmployee, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedEmployee);

            setEmployees((prevEmployees) =>
                prevEmployees.filter((employee) => employee.id !== deleteEmployee.id)
            );
            setIsDeleteModalOpen(false);

            console.log("Employee deleted successfully");
        } catch (error) {
            console.error("Error deleting employee:", error);
        }
    };

    const handleStatusModalOpen = (employee) => {
        setCombineDetails(employee);
        setIsStatusModalOpen(true);
    };

    const handleUpdateEmployees = () => {
        toast.success("Successfully uploaded");
        fetchCombinedData();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = employees.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showEmployeeDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Resign/Termination List
                                        </h6>
                                    </div>
                                    <div className="card-body" style={{ height: "calc(100% - 40px)" }}                                    >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                           
                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Employee Photo</th>
                                                            <th>Employee Name</th>
                                                            <th>Status</th>
                                                            <th>Reason</th>
                                                            <th>Start Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItems.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="12" className="text-center">Thier is No Resign Termination.</td>
                                                            </tr>
                                                        ) : (
                                                            currentItems.map((leaveRequest, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr>
                                                                        <td>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${leaveRequest.passportSizePhoto}`}
                                                                                style={{ width: "90px" }} className="employee-image"
                                                                                alt="Employee"
                                                                            />
                                                                        </td>
                                                                        <td>{leaveRequest.employeeName}</td>
                                                                        <td>{leaveRequest.activeInactiveDetails.status}</td>
                                                                        <td>{leaveRequest.activeInactiveDetails.reason}</td>
                                                                        <td>{formatDate(leaveRequest.activeInactiveDetails.fromDate)}</td>
                                                                        <td>
                                                                            <button onClick={() => handleStatusModalOpen(leaveRequest)} className="btn btn-outline-success">
                                                                                Rejoin
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(employees.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(employees.length / itemsPerPage) && 'disabled'}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={handleDeleteConfirmation}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                    {isStatusModalOpen && (
                        <ActiveInactiveModal
                            employee={combineDetails}
                            onClose={() => setIsStatusModalOpen(false)}
                            onUpdate={handleUpdateEmployees}
                            userType={localStorage.getItem('userType')}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Resign_TerminationList;
